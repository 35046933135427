/* ============================================
   General Styles 
   ============================================ */
/* General Reset */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensures padding/margin don't affect total width/height */
  }

  /* Body Styles */
  body {
    background-color: white; /* Set the background color you want */
    font-family: 'Noto Serif', serif;
    margin: 0;
    min-height: 100vh; /* Ensure the body takes at least the full viewport height */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  /* Container for all content */
  .app {
    max-width: 1200px; /* Restrict the content width for readability */
    margin: 0 auto; /* Center the content horizontally */
  }

  /* Additional styling to ensure layout consistency */
  h1 {
    margin-bottom: 15px; /* Add spacing below the heading */
    text-align: center; /* Center-align only <h1> */
  }
  
  h2, h3, p {
    margin-bottom: 15px; /* Add spacing below the elements */
    text-align: left; /* Align <h2>, <h3>, and <p> to the left */
  }

  .social-links {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-icon {
    font-size: 1.5em;
    color: #333;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #0077b5; /* LinkedIn blue */
  }
  
/* Button row aligned to the left with spacing */
.button-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px; /* Large gap between button groups */
  margin: 20px 0;
}

.button-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px; /* Smaller gap between the main button and caret toggle */
}

button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Noto Serif', serif;
  border-radius: 4px;
}

button:hover {
  background-color: #555;
}

/* Circular caret toggle button */
.submenu-toggle {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  background-color: white;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  font-weight: bold;
}

/* Active state for caret toggle */
.submenu-toggle.active {
  background-color: #555;
  color: white;
}

.submenu-toggle:hover {
  background-color: #777;
}

/* Submenu styling */
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #333;
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.submenu label {
  display: block;
  margin: 5px 0;
}

.submenu input {
  margin-right: 10px;
}
  
  select {
    padding: 5px;
    margin: 10px;
    font-family: 'Noto Serif', serif;
  }

    /* General corpus list */
/* Corpus Renderer List */
.corpus-renderer-list {
  list-style: none;
  padding: 0;
  font-size: 24px; /* Default font size */
}

.corpus-renderer-list li {
  margin: 5px 0;
  color: inherit; /* Default text color */
  cursor: default;
  font-size: 20px; /* Specific size for library items */

}

.corpus-renderer-list li:hover {
  color: #007BFF;
}

/* Primary List */
.primary-list li {
  margin: 10px 0;
  font-size: 24px; /* Larger font for primary list */
  cursor: pointer; /* Pointer cursor for clickable items */
}

.primary-list li:hover {
  color: #007BFF; /* Hover color for primary list */
}

/* Corpus Library List */
.corpus-library-list {
  list-style: none;
  padding: 0;
  font-size: 24px; /* Specific size for library items */
}

.corpus-library-list li {
  margin: 8px 0;
  color: #333;
  cursor: pointer;
  font-size: 20px; /* Specific size for library items */

}

.corpus-library-list li:hover {
  color: #0056b3; /* Hover effect for library items */
}

/* Articles List */
.articles-list {
  list-style: none;
  padding: 0;
  font-size: 18px;
}

.articles-list li {
  margin: 5px 0;
  color: #444;
  font-size: 20px; /* Specific size for library items */

}

.articles-list li a {
  color: #0056b3;
  text-decoration: none;
}

.articles-list li a:hover {
  text-decoration: underline;
}
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin: 10px 0;
    font-size: 12px;
    cursor: pointer;
  }
  

  .statistics-list li {
    font-size: 14px; /* Smaller font size for statistics */
    cursor: default; /* Remove pointer cursor */
    color: inherit; /* Use the default text color */
  }
  
  .statistics-list li:hover {
    color: inherit; /* Remove hover effect */
  }

  .statistics-section {
    margin: 20px 0;
    text-align: center;
  }
  
  .statistics-section h4 {
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .statistics-list {
    list-style-type: none;
    padding: 0;
    font-size: 0.9rem; /* Small text */
    line-height: 1.5;
    color: #555; /* Muted color for stats */
  }
  
  .contact-info {
    margin-top: auto;
    text-align: center;
    padding: 10px 0;
  }
  
/* ============================================
   Icon Grid Styles (Flexbox instead of Grid)
   ============================================ */
   .icon-grid {
    display: flex; /* Use flexbox to align items in a row */
    justify-content: center; /* Centers the icons horizontally */
    align-items: center; /* Aligns the icons vertically */
    gap: 20px; /* Adds space between the icons */
  }
  
  .icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border-radius: 15px;
    padding: 20px;
    transition: transform 0.2s;
  }
  
  .icon-item:hover {
    transform: scale(1.05);
  }
  
  .icon {
    font-size: 3em;
    margin-bottom: 10px;
  }
  
  .icon-item span {
    font-size: 1.2em;
    margin-top: 10px;
  }

  /* ============================================
     Main Menu Styles 
     ============================================ */

     .custom-icon {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

  .mainmenu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    background-color: transparent;
  }
  
  .mainmenu-title {
    font-family: 'Cinzel', serif;
    font-size: 48px;
    color: #333;
    margin-bottom: 40px;
  }
  
  .mainmenu-list {
    list-style-type: none;
    padding: 0;
  }
  
  .mainmenu-item {
    font-family: 'Noto Serif', serif;
    font-size: 24px;
    color: #007BFF;
    cursor: pointer;
    margin: 20px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mainmenu-item .mainmenu-icon {
    margin-right: 10px;
    font-size: 20px;
  }
  
  /* ============================================
     Project News Styles
     ============================================ */
  .project-news-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Noto Serif', serif;
    font-size: 18px;
    color: #333;
  }
  
  .project-news-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
/* ============================================
   Menu and Navigation Styles 
   ============================================ */
   .menu-container {
    position: absolute; /* Changed to absolute to stop persistence on scroll */
    right: 20px;
    top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; /* Reduced gap between icons */
    height: 60px;
    z-index: 1000;
  }
  
  .menu-button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    border: none;
  }
  
  .menu-button:hover {
    background-color: #555;
  }
  
  .menu-button.active {
    background-color: #4a4a4a;
  }
  
  .mindroots-button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border: none;
  }
  
  .mindroots-button .button-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* Container for settings text on left and buttons on right */
  .settings-top-section {
    display: flex;
    align-items: flex-start;
  }
  
  .settings-text {
    flex: 1;
  }
  
  .settings-links {
    display: flex;
    flex-direction: row;
    gap: 8px; /* Adjusted spacing */
    margin-left: auto;
    align-items: flex-end;
  }
  
  .small-icon-button {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    border: none;
  }
  
  .small-icon-button:hover {
    background-color: #555;
  }

  .navigation-buttons {
    display: flex;
    flex-direction: row; /* Ensures buttons are arranged horizontally */
    justify-content: left; /* Centers buttons within the container */
    align-items: left; /* Aligns buttons vertically */
    gap: 10px; /* Adds space between buttons */
  }
  
  
  /* ============================================
     Image and Content Styles 
     ============================================ */
  .responsive-image {
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }
  
  .graph-image-container {
    text-align: center;
    margin: 20px 0;
  }
  
  .content-container {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .read-more-link {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .read-more-link:hover {
    text-decoration: underline;
  }
  
  /* ============================================
     Info Bubble Styles 
     ============================================ */

     .info-bubble {
      position: fixed; /* Use fixed to center relative to the viewport */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); /* This ensures it is centered */
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      max-width: 300px;
      max-height: 200px; /* Set a fixed height */
      width: 100%; /* Take full width available, but max-width will limit it */
      overflow-y: auto; /* Enable vertical scrolling */
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      z-index: 1000;
      -webkit-overflow-scrolling: touch; /* Ensure smooth scrolling on iOS */
    }
    
    /* Preserve smooth scrolling for touch devices */
    .info-bubble-content {
      margin-top: 20px;
      padding-right: 10px; /* Add space for scroll bar */
      max-height: 300px; /* Ensure the content can scroll */
      overflow-y: scroll; /* Ensure scrollability */
      -webkit-overflow-scrolling: touch; /* Ensure smooth scrolling on iOS */
    }

.close-button {
  position: absolute;
  top: -10px;
  right: -5px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
  font-weight: bold;
  z-index: 1001; 
  touch-action: manipulation; /* Optimize touch behavior */

}

.close-button:focus {
  outline: none; /* Remove focus outline for better mobile experience */
}

.close-button:active {
  color: #e74c3c; /* Change color on touch/click */
}

.close-button:hover {
  color: #e74c3c; /* Change color on hover */
  background-color: transparent;
  transform: scale(1.2);
}


  
/* ============================================
   Simple Toggles/Buttons
   ============================================ */

  
  .toggle-slider {
    display: flex;
    justify-content: space-around; /* Reduces space between elements */
    width: 100%;
  }
  
  .selected {
    font-weight: bold;
    color: #fff;
  }
  
  .unselected {
    font-weight: normal;
    color: #999;
  }

  .language-toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .language-toggle {
    position: fixed; /* Makes the toggle follow the scroll */
    top: 24px; /* Distance from the top of the viewport */
    left: 20px; /* Distance from the left of the viewport */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    background-color: #444;
    border-radius: 20px;
    padding: 5px;
    z-index: 1000; /* Ensure it appears above other elements */
  }

  
  .button-icon {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image scales properly within the circle */
  }

  .main-site-link {
    margin-top: 20px;
    text-align: center;
  }
  
  .main-site-link a {
    text-decoration: none;
    color: #333; /* Adjust color as needed */
    font-size: .7em; /* Adjust size as needed */
  }
  
  .main-site-link a:hover {
    text-decoration: underline;
  }

/* ============================================
   Markdown Homepage Styles 
   ============================================ */
  /* General styling for the markdown container */
  .markdown-homepage {
    background-color: #2c2c2c;
    color: #dcdcdc;
    padding: 20px;
    font-family: 'Noto Serif', serif;
    line-height: 1.6;
    min-height: auto; /* Remove the fixed minimum height */
    margin-bottom: 20px; /* Add some consistent spacing between articles */
  }


/* Arabic Text Styling */
.markdown-homepage.rtl {
  direction: rtl; /* Ensure text flows from right to left */
  text-align: right; /* Align text to the right */
  unicode-bidi: bidi-override; /* Override the bidirectional algorithm */
}

/* Override heading alignment for RTL */
.markdown-homepage.rtl h1,
.markdown-homepage.rtl h2,
.markdown-homepage.rtl h3 {
  text-align: right; /* Align headers to the right */
}

/* Styling for English text */
.markdown-homepage.ltr {
  direction: ltr; /* Set text direction to left-to-right */
  text-align: left; /* Align text to the left */
}

/* Headings should not be justified */
/* Headings should not be justified */
.markdown-homepage h1 {
  font-size: 2.5em; /* Largest for H1 */
  color: #e8e8e8; 
  margin-bottom: 0px;
  font-weight: bold;
  text-align: left;
}

.markdown-homepage h2 {
  font-size: 2em; /* Slightly smaller for H2 */
  color: #e8e8e8;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: left;
}

.markdown-homepage h3 {
  font-size: 1.75em; /* Smaller for H3 */
  color: #e8e8e8;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: left;
}

/* Paragraph styling */
.markdown-homepage p {
  margin-bottom: 15px;
  text-align: justify; /* Justify paragraphs */
}

/* Link styling */
.markdown-homepage a {
  color: #66b2ff; /* Softer blue */
  text-decoration: none;
  font-weight: bold;
}

.markdown-homepage a:hover {
  text-decoration: underline;
  color: #ff8c66; /* Softer hover color */
}

/* Adjust list styling for better readability */
.markdown-homepage ul,
.markdown-homepage ol {
  margin-left: 20px;
  padding-left: 20px;
  list-style-position: outside; /* Changed to outside for better spacing */
}

.markdown-homepage ul li,
.markdown-homepage ol li {
  margin-bottom: 5px; /* Add some spacing between list items */
}

/* Blockquote styling */
.markdown-homepage blockquote {
  border-left: 5px solid #888; /* Darker gray border for blockquote */
  padding-left: 10px;
  color: #c0c0c0; /* Softer off-white for blockquote text */
}

/* Code block styling */
.markdown-homepage code {
  background-color: #444; /* Slightly lighter background for code blocks */
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace; /* Ensure code has a monospaced font */
}

.markdown-page,
.markdown-mindroots {
  background: white;
  color: black;
}
  
/* ============================================
   Media Queries for Mobile Responsiveness 
   ============================================ */
   
   @media (max-width: 768px) {
    .overlay {
      margin: 10px;
      padding: 10px;
      margin-top: 80px;
    }
  
    button {
      padding: 8px 16px;
      font-size: 14px;
    }
  
    select {
      padding: 4px;
      font-size: 14px;
    }
  
    li {
      font-size: 20px;
    }
  
    .menu-button {
      padding: 8px;
      font-size: 16px;
    }
  
    .mainmenu-title {
      font-size: 36px;
    }
  
    .mainmenu-item {
      font-size: 20px;
    }
  
    .info-bubble {
      max-width: 90%; /* Adjust width for smaller screens */
      max-height: 60%; /* Adjust height for smaller screens */
    }
  
    .info-bubble-content {
      max-height: 70vh; /* Content scrolls within the bubble */
      overflow-y: scroll; /* Ensure vertical scrolling */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
    }
  
    .close-button {
      font-size: 20px; /* Larger close button for better tapability */
    }
  }
  
  @media (max-width: 480px) {
    .overlay {
      margin: 5px;
      padding: 5px;
      margin-top: 80px;
    }
  
    button {
      padding: 6px 12px;
      font-size: 12px;
    }
  
    select {
      padding: 3px;
      font-size: 12px;
    }
  
    li {
      font-size: 18px;
    }
  
    .menu-button {
      padding: 6px;
      font-size: 14px;
    }
  
    .mainmenu-title {
      font-size: 28px;
    }
  
    .mainmenu-item {
      font-size: 18px;
    }
  
    .info-bubble {
      max-width: 80%; /* Adjust width even more for very small screens */
      max-height: 50%; /* Adjust height for smaller screens */
      transform: translate(-55%, -50%); /* Shift it a bit more to the left */

    }
  
    .info-bubble-content {
      max-height: 50vh; /* Adjust content height to fit mobile screen */
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch; /* Enable smooth scrolling */
    }
  
    .close-button {
      font-size: 18px; /* Slightly smaller for mobile */
    }
  }

